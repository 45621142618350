///authorization/roles
import { defineStore } from 'pinia'
import { useApiStore } from './api'
import { ref } from 'vue'
import { isUuid } from '../utils/uuid'
import { withCache } from './cache'
import { ActionDto, ActionsFromOpenApiDto, InputPropertyDto, OrderedRecordDto, OutputPropertyDto, TryActionDto } from '../types/api'

export const useActionsStore = defineStore('actionsStore', () =>
{   
    const store = useApiStore();
    const list = async (o?: {
        platformId: string,
        includeInputsAndOutputs?: boolean,
    }) => (await store.api.actions.actionsList(o)).data;

    // public apis get a cache
    const listPublic = withCache(
        async (o?: { platformId: string }) => (await store.api.actions.actionsPublicList(o)).data,
        {
            keyFn: (o) => o?.platformId || 'default'
        }
      );

    const detailPublic = withCache(
        async (id: string) => (await store.api.actions.actionsPublicDetail(id)).data,
        {
            keyFn: (id) => `action_${id}`
        }
    );

    let createInputProperty = async (actionId: string, data: InputPropertyDto) => (await store.api.actions.actionsInputPropertiesCreate(actionId, data)).data; 
    
    let updateInputProperty = async (actionId: string, id: string, data: InputPropertyDto) => (await store.api.actions.actionsInputPropertiesUpdate(actionId, id, data)).data;
    
    let deleteInputProperty = async (actionId: string, id: string) => (await store.api.actions.actionsInputPropertiesDelete(actionId, id)).data;

    let createOutputProperty = async (actionId: string, data: OutputPropertyDto) => (await store.api.actions.actionsOutputPropertiesCreate(actionId, data)).data; 
    
    let updateOutputProperty = async (actionId: string, id: string, data: OutputPropertyDto) => (await store.api.actions.actionsOutputPropertiesUpdate(actionId, id, data)).data; 

    let deleteOutputProperty = async (actionId: string, id: string) => (await store.api.actions.actionsOutputPropertiesDelete(actionId, id)).data;

    let updateHttpRequestInfo = async (actionId: string, id:string, data: any) => (await store.api.actions.actionsHttpRequestInfoUpdate(actionId, id, data)).data;

    let updateInputPropertyOrder = async (actionId: string, data: OrderedRecordDto[]) => (await store.api.actions.actionsInputPropertiesOrderUpdate(actionId, data)).data;

    let detail = async (id: string) => (await store.api.actions.actionsDetail(id)).data;

    let update = async (id: string, data: ActionDto) => (await store.api.actions.actionsUpdate(id, data)).data;

    let create = async (data: ActionDto) => (await store.api.actions.actionsCreate(data)).data;

    let del = async (id: string) => (await store.api.actions.actionsDelete(id)).data;

    let createFromOpenApi = async (data: ActionsFromOpenApiDto) => (await store.api.actions.actionsOpenapiCreate(data)).data;

    let exportToJson = async (platformId: string) => (await store.api.actions.actionsExportDetail(platformId)).data;

    let tryAction = async (id: string, data: TryActionDto) => (await store.api.actions.actionsTryCreate(id, data)).data;
    
    let succeedAction = async (id: string) => (await store.api.actions.actionsSucceedCreate(id)).data;

    // this is used by the action editor to keep track of the current action
    const instance = ref<ActionDto | null>(null)

    const instanceIsLoading = ref(false)

    const detailInstance = async (actionId: string): Promise<ActionDto> => {
        instanceIsLoading.value = true
        try {
            const data = await detail(actionId) // Your API call here
            
            // Process codeName if it's a UUID
            if (isUuid(data.codeName || '')) {
                data.codeName = ''
            }
            
            // Set the instance
            instance.value = data
            return data
        } finally {
            instanceIsLoading.value = false
        }
    }


    return {
        list, detail, update, create, del,
        listPublic, detailPublic,
        createInputProperty, updateInputProperty, deleteInputProperty, updateInputPropertyOrder,
        createOutputProperty, updateOutputProperty, deleteOutputProperty,
        updateHttpRequestInfo,
        tryAction, succeedAction,
        createFromOpenApi,exportToJson,
        detailInstance, instance, instanceIsLoading
    }
});