///authorization/roles
import { defineStore } from 'pinia'
import { useApiStore } from './api';
import { WorkflowDto, Step, PublicActionDto, PublicPlatformDto } from '../types/api';
import { ref, Ref, computed } from 'vue';
import { v4 as uuidv4 } from 'uuid';

export const useWorkflowsStore = defineStore('workflowsStore', () =>
{   
    const store = useApiStore();
    let list = async (o: {appId? : string}) => (await store.api.workflows.workflowsList(o)).data;

    let detail = async (id: string) => (await store.api.workflows.workflowsDetail(id)).data;

    let update = async (id: string, data: WorkflowDto) => (await store.api.workflows.workflowsUpdate(id, data)).data;

    let create = async (data: WorkflowDto) => (await store.api.workflows.workflowsCreate(data)).data;

    let del = async (id: string) => (await store.api.workflows.workflowsDelete(id)).data;

    const findAndClearDocContentObjects = (obj:any, targetStepId:string) => {
        if (!obj || typeof obj !== 'object') {
            return;
        }
    
        if (Array.isArray(obj)) {
            obj.forEach(item => findAndClearDocContentObjects(item, targetStepId));
            return;
        }
    
        Object.entries(obj).forEach(([key, value]) => {
            if (value && typeof value === 'object') {
                if (isDocContentObject(value)) {
                    const contentEl = (value as any).content[0];
                    if (isDocSubContentObject(contentEl)) {
                        const subContentEl = contentEl.content[0];
                        if (subContentEl && subContentEl.type === 'WorkflowStepData') {
                            const stepId = subContentEl?.attrs?.value?.stepId;
                            if (stepId && stepId === targetStepId) {
                                console.log('Clearing doc content object', value);
                                obj[key] = '';
                            }
                        }
                    }
                    
                } else {
                    findAndClearDocContentObjects(value, targetStepId);
                }
            } else {
                findAndClearDocContentObjects(value, targetStepId);
            }
        });
    };

    const isDocSubContentObject = (obj: any) => {
        if (!obj) {
            return false
        }
        const props = Object.keys(obj);
        return (
            props.includes('type') &&
            props.includes('content') &&
            obj.type === 'paragraph'
        );
    }
    
    const isDocContentObject = (obj:any) => {
        const props = Object.keys(obj);
        return (
            props.includes('type') &&
            props.includes('content') &&
            props.length === 2 &&
            obj.type === 'doc'
        );
    };

    const getDefaultStep = (action: PublicActionDto, platform: PublicPlatformDto): Ref<Step> => {
        let step = {
            id: uuidv4(),
            actionId: action.id,
            actionName: action.name,
            actionSummary: action.summary,
            platformId: action.platformId,
            platformLogoUrl: platform.logoUrl,
            platformName: platform.productName,
            stepType: action.stepTypeName,
            reference: action.codeName,
            name: action.name,
            errorBehavior: 'Terminate',
            retryInterval: '00:10:00',
            inputs: { },
            outputs: { }
        } as Step
        if (step.stepType == 'HttpRequest') {
            step.inputs = step.inputs || {}
            step.inputs.parameters = step.inputs.parameters || {}
            step.inputs.headers = step.inputs.headers || {}
            step.inputs.method = 'GET'
            step.inputs.url = 'https://'
            step.inputs.contentType = 'JSON'
            step.inputs.throwOnInvalidResponseCode = true
    
            step.outputs = {
                errorMessage: 'step.errorMessage',
                responseCode: 'step.responseCode',
                responseBody: 'step.responseBody'
            }
        }
        if (step.stepType == 'Decide') {
            step.name = 'Decision'
            step.actionSummary = 'Take a path based on a condition'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-arrow-decision'
            step.selectNextStep = [{
                stepId: '',
                expressions: []
            }, {
                stepId: '',
                expressions: []
            }]
        }
        if (step.stepType == 'Search') {
            step.name = 'Search'
            step.actionSummary = 'Search for a value in text'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-magnify'
            step.inputs = step.inputs || {}
            step.inputs.haystack = ''
            step.inputs.needle = ''
            step.inputs.caseSensitive = false
        }
        if (step.stepType == 'SelectFromJson') {
            step.name = 'Select From JSON'
            step.actionSummary = 'Select a value from JSON'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-code-json'
            step.inputs = step.inputs || {}
            step.inputs.json = ''
            step.inputs.path = ''
        }
        if (step.stepType == 'Redirect') {
            step.name = 'Redirect'
            step.actionSummary = 'Redirect a user to a location'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-arrow-right-thick'
            step.inputs = step.inputs || {}
            step.inputs.inputUrl = ''
        }
        if (step.stepType == 'PhoneExtractor') {
            step.name = 'Phone Number Extractor'
            step.actionSummary = 'Extract and format phone numbers'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-phone'
            step.inputs = step.inputs || {}
            step.inputs.text = ''
        }
        if (step.stepType == 'GoTo') {
            step.name = 'Go To'
            step.actionSummary = 'Go to a step in the workflow'
            step.platformLogoUrl = 'https://api.mdisvg.com/v1/i/mdi-map-marker'
        }
        if(step.stepType == 'If'){
            step.inputs = step.inputs || {}
            step.inputs.condition= ''
            step.do = [[]]
        }
        if(step.stepType == 'Code'){
            step.inputs = step.inputs || {}
        }
        return ref(step)
    }


    // this is used by the workflow editor to keep track of the current workflow
    let _instance = ref({} as WorkflowDto)

    const getInstance = () => { return _instance }
    const setInstance = (workflow: WorkflowDto) => { _instance = ref(workflow); return _instance; }

    const findAllPathsToStep = (workflow: WorkflowDto, targetStepId: string): Step[][] => {
        const allPaths: Step[][] = [];
        
        const traverse = (currentStepId: string | null | undefined, currentPath: Step[]) => {
            // If we found our target, save this path
            if (currentStepId === targetStepId) {
                allPaths.push([...currentPath]);
                return;
            }
    
            const step = workflow.definition?.steps?.find(s => s.id === currentStepId);
            if (!step) return;
    
            // Check for cycles to prevent infinite recursion
            if (currentPath.some(s => s.id === step.id)) return;
    
            const nextPath = [...currentPath, step];
    
            // Handle direct next step
            if (step.nextStepId) {
                traverse(step.nextStepId, nextPath);
            }
    
            // Handle conditional next steps
            step.selectNextStep?.forEach(selection => {
                traverse(selection.stepId, nextPath);
            });
        };
    
        // Start from all root steps (steps with no parents)
        workflow.definition?.steps?.forEach(step => {
            const hasParents = workflow.definition?.steps?.some(s => 
                s.nextStepId === step.id || 
                s.selectNextStep?.some(sel => sel.stepId === step.id)
            );
    
            if (!hasParents) {
                traverse(step.id, []);
            }
        });
    
        return allPaths;
    };

    // loop over the steps backwards following the parent to the top of the tree
    const getSharedParentsOfStep = (workflow: WorkflowDto, stepId: string) => {
        // Find all possible paths to the target step
        const allPaths = findAllPathsToStep(workflow, stepId);
        
        if (allPaths.length === 0) return [];
    
        // Find steps that appear in all paths
        const sharedSteps = allPaths[0].filter(step =>
            allPaths.every(path =>
                path.some(pathStep => pathStep.id === step.id)
            )
        );
    
        // Add the trigger step at the beginning
        sharedSteps.unshift({
            id: '',
            name: workflow.triggerName || 'Trigger',
            platformLogoUrl: workflow.platformLogoUrl,
            sampleData: workflow.triggerSampleData
        });
    
        return sharedSteps;
    };

    const getDirectParentsOfStep = (workflow: WorkflowDto, stepId: string) => {
        const steps = [] as Step[]
        (window as any).w = workflow
        let currentStep = workflow.definition?.steps?.find(s => s.id == stepId)
        while (currentStep) {
            currentStep = workflow.definition?.steps?.find(s =>
                s.nextStepId && s.nextStepId === currentStep?.id || s.selectNextStep?.find(o => o.stepId === currentStep?.id))

            if (currentStep) {
                steps.unshift(currentStep)
            }
        }
        steps.unshift({
            id: '',
            name: workflow.triggerName || 'Trigger',
            platformLogoUrl: workflow.platformLogoUrl,
            sampleData: workflow.triggerSampleData
        })
        return steps
    }

    let getPriorStepData = (workflow: WorkflowDto, stepId: string) =>{
        // we need to create a fake representation of all the data in the workflow up to this point
        // this will include the trigger, which shows up as a step with a blank id
        const parentSteps = getSharedParentsOfStep(workflow, stepId)
        const data = {} as any
        for (const parent of parentSteps) {
            data['step_' + parent.id] = data['step_' + parent.id] || {}
            if (parent.sampleData) {
                data['step_' + parent.id]['output'] = JSON.parse(parent.sampleData)
            }
        }
        return data
    }

    return {
        list, detail, update, create, del,
        setInstance, getInstance, getDefaultStep,
        getDirectParentsOfStep, getSharedParentsOfStep, getPriorStepData,
        findAndClearDocContentObjects
    }
});