<script lang="ts" setup>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import { ref, computed } from 'vue'
import { StepInputFieldValue } from '../../../types/stepInput';

import { useWorkflowsStore } from '../../../store/workflows';

const props = defineProps(nodeViewProps)

const input = computed(() : StepInputFieldValue =>  {
  return (props.node.attrs.value as StepInputFieldValue)
})


const store = useWorkflowsStore()
const workflow = store.getInstance().value
const steps = workflow.definition?.steps;

const stepNumber = ref(1)
const stepName = ref(workflow.triggerName)
const propertyName = ref(input.value.stepOutputPropertyDisplayName ?? input.value.stepOutputPropertyName)

if (input.value.stepId && steps) {
  // find the step and add the details
  const step = steps.find(step => step.id === input.value.stepId)
  stepNumber.value = step?.number ?? 0
  stepName.value = step?.name
}
</script>

<template>
    <node-view-wrapper class="workflow-step-data">
        <img class="logo" v-if="input?.platformLogoUrl" :src="input?.platformLogoUrl">
        <span class="property-name">{{ stepNumber }}. {{ propertyName }}</span>
    </node-view-wrapper>
</template>
<style>
.workflow-step-data {
  display: inline-block;
  padding: 4px 4px;
  border:1px solid #ccc;
  border-radius: 4px;
  line-height: 1em;
  text-align: center;
  width:max-content;
  white-space: nowrap;
}
.workflow-step-data .property-name {
  padding-left:8px;
}
.workflow-step-data img.logo {
  width: 16px;
  height:16px;
  border-radius: 8px;
}
.workflow-step-data.ProseMirror-selectednode{
  border:2px solid rgb(var(--v-theme-primary));
}
</style>