<script setup lang="ts">
import { useHelpStore } from '@shared/store/help'
import { computed, useAttrs, ref, useSlots } from 'vue'
import { codeNameRules } from '@/utils/validation';

const attrs = useAttrs()
const props = defineProps({
    helpKey: { type: String, required: true },
    helpSection: { type: String, default: '' },
    showQuestion: { type: Boolean, default: false },
    showLearn: { type: Boolean, default: true },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})

const required = ref(false)
const rules = [] as Array<any>;
props.validators.forEach((validatorName: String) => {
    switch (validatorName) {
        case 'required':
            required.value = true
            rules.push((v: any) => !!v || `${attrs.label} is required.`)
            break;
        case 'codename':
            //rules.push((v: any) => /^[a-z_\.]+$/.test(v) || `${attrs.label} must be lowercase, and contain only letters, periods, and underscores.`)
            codeNameRules.forEach((rule: any) => {
                rules.push(rule)
            })
            break;
        case 'url':
            rules.push((v: any) => !v ? true : /^(http|https):\/\/[^ "]+$/.test(v) || `${attrs.label} must be a valid URL.`)
            break;
        case 'uri':
            rules.push((v: any) => !v ? true : /^(data|http|https):[^ "]+$/.test(v) || `${attrs.label} must be a valid URI.`)
            break;
    }
})

const slots = useSlots() as any

</script>
<template>
    <v-text-field 
        v-bind="$attrs"
        validate-on="blur"
        :rules="rules"
        :persistent-hint="true"
        >
        <template #append v-if="props.helpKey && showQuestion">
            <v-icon size="large" @click.stop="showHelp(props.helpKey)" :color="color">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <template v-slot:message="{ message }">
            <span v-html="message"></span> <span v-if="showLearn"><a style="cursor: pointer;" class="learn-more" @click.stop.prevent="showHelp(props.helpKey + '#' + props.helpSection)">Learn more</a>.</span>
        </template>
        <template #label>{{ attrs.label }} <span v-if="required" class="required">(Required)</span></template> 
        <template v-for="(_,name) in slots" v-slot:[name]="slotData"><slot :name="name" v-bind="slotData" /></template>

    </v-text-field>
</template>