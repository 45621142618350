<script lang="ts" setup>
import { ref, watch, computed } from 'vue';

const props = withDefaults(defineProps<{
    isTool: boolean,
    logoUrl: string | undefined | null
}>(), {
    isTool: false
});

const localUrl = ref(props.logoUrl);
watch(() => props.logoUrl, (newVal) => {
    localUrl.value = newVal;
}, { deep: true });

const size = ref(24);
const padding = props.isTool ? 3 : 0;
const style = computed(() => {
    return `height:${size.value}px;width:${size.value}px;margin-left:4px;margin-right:4px;`
})
</script>
<template>
    <v-avatar v-if="localUrl" :style="style">
        <img :style="`height:${size}px;width:${size}px;padding:${padding}px`" :src="localUrl" />
    </v-avatar>
    <v-avatar v-else :style="style">
        <v-icon>mdi-progress-helper</v-icon>
    </v-avatar>
</template>