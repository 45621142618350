<script lang="ts" setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
import { Document } from '@tiptap/extension-document'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Text } from '@tiptap/extension-text'
import WorkflowStepData from './TipTap/WorkflowStepData'
import { computed, ref, watch, nextTick, Ref } from 'vue'
import { StepInputFieldValue } from '../../types/stepInput'
import { Step, WorkflowDto } from '../../types/api'
import { v4 } from 'uuid'
import { TreeNode } from '../../types/treeNode'
import StepValuePicker from './StepValuePicker.vue'
import { useHelpStore } from '../../store/help'

const model = defineModel<any>();
const workflow = defineModel<WorkflowDto>('workflow', { required: false });
const step = defineModel<Step>('workflowStep', { required: false });

const props = withDefaults(defineProps<{
	label: string,
	appendInnerIcon?: string,
	hint?: string,
	helpKey?: string,
	helpSection?: string,
	disabled?: boolean,
	persistentHint?: boolean,
	'onClick:appendInner'?: (e: MouseEvent) => void
}>(), {
	persistentHint: true,
	disabled: false
})

const isUpdating = ref(false)

let v = model.value
let empty = JSON.stringify({
    "type": "doc",
    "content": [
        {
            "type": "paragraph"
        }
    ]
});



const editor = useEditor({
	content: v,
    extensions: [Document, Paragraph, Text, WorkflowStepData],
	onUpdate({ editor }) {
		isUpdating.value = true
		// ensures a "blank" editor is not saved
		let json = editor.getJSON()
		if(empty == JSON.stringify(json)){
			model.value = ''
		}else{
			model.value = json
		}
		nextTick(() => {
			isUpdating.value = false
		})
	},
	onFocus() {
		isEditorFocused.value = true
	},
	onBlur() {
		isEditorFocused.value = false
	}
})

const setFocus = () => {
	editor.value?.commands.focus()
}

watch(model, (value) => {
	if (isUpdating.value || !value) return
	// if this is just text, insert it into the editor
	if(model.value instanceof String || typeof model.value === 'string') {
		editor.value?.commands.setContent({
			type: 'text',
			text: model.value as string
		})
	}else if(value){
		// if this is a tree node, insert it into the editor
		editor.value?.commands.setContent({
			type: 'WorkflowStepData',
			attrs: {
				value: model.value as StepInputFieldValue
			},
			content: []
		})
	}
})

const hasContent = computed(() => model.value !== '' && model.value !== '<p></p>')
const isEditorFocused = ref(false)


const menu = ref(false);
const menuActivator = ref('');

const showMenuAtPosition = (event: MouseEvent) => {
    // all we do here is change the menu activator to the parent element
    let parent = event.target as HTMLElement;
    while(parent.classList.contains('v-input') === false) {
        parent = parent.parentElement!;
    }
    if (!parent.id) {
        // ensure we have an id
        parent.id = 'random-' + v4()
    }

    //menuFieldModel = fieldModel;
    menuActivator.value = `#${parent.id}`;
};

const stepValuePicked = (node: TreeNode) => {
    if (node) {
        // pass node, action, input etc
		const step = node.tag as Step
		model.value = {
			stepId: step.id!,
			stepOutputPropertyName: node.key!,
			stepOutputPropertyDisplayName: node.name!,
			stepOutputPropertySelector: node.jsonpath!,
			platformId: step.platformId!,
			platformLogoUrl: step.platformLogoUrl!
		} as StepInputFieldValue
        menu.value = false;
    }
}

watch(menu, (newVal) => {
    if (!newVal) {
        menuActivator.value = '#offscreen';
    }
});

const appendClicked = (e: MouseEvent) => {
	showMenuAtPosition(e);
}

const help = useHelpStore()
const go = () => {
	help.setContext(props.helpKey + '#' + props.helpSection)
}

</script>

<template>
	<div>
		<v-menu
            v-model="menu"
            :close-on-content-click="false"
            location="left"
            :offset="8"
            :max-width="400"
            :min-width="300"
            :activator="menuActivator"
        >
            <v-card ref="menu-content">
                <v-card-text>
                    <StepValuePicker v-if="workflow && step" v-model:workflow="workflow" v-model:step="step" @picked="stepValuePicked"></StepValuePicker>
                </v-card-text>
            </v-card>
        </v-menu>
		
		<v-input 
			:hint="props.hint"
			:persistent-hint="props.persistentHint"
			class="editor-input-wrapper" 
			:class="{
				'has-content': hasContent,
				'is-focused': isEditorFocused
			}">
			<template v-slot:message="{ message }">
				<span v-html="message"></span> <span v-if="props.helpKey"><a style="cursor: pointer;" class="learn-more" @click.stop.prevent="go">Learn more</a>.</span>
			</template>
			<v-field variant="solo"
				:label="props.label"
				:dirty="hasContent"
				:disabled="disabled"
				:active="isEditorFocused">
				<div class="tiptap" @click.stop="setFocus">
					<editor-content :editor="editor" />
				</div>
				
				<template v-slot:append-inner>
					<div class="d-flex">
					
						<slot name="appendInner"></slot>
						<v-icon 
							@click="appendClicked"
							icon="mdi-plus-box-outline">
						</v-icon>
					</div>
				</template>
			</v-field>
		</v-input>
		<div id="offscreen" style="position:fixed;left:-99999px"></div>
	</div>
</template>
  
<style lang="scss">
  /* Basic editor styles */
  .tiptap {
    :first-child {
      margin-top: 0;
    }
  }
</style>
<style scoped>
.v-input .v-field{
  	padding:16px 16px 6px 16px;
}

.editor-input-wrapper :deep(.v-field__field) {
  	/* Ensure proper stacking context */
  	position: unset;
}

.editor-input-wrapper :deep(.ProseMirror) {
  	/* Adjust editor content positioning */
  	outline: none;
}
.editor-input-wrapper .tiptap{
	padding-top:8px;
	width: 100%;
	cursor:text;
}
:deep(.editor-input-wrapper .v-label.v-field-label){
  	margin-inline-start: 0;
}
:deep(.v-field--center-affix .v-field__append-inner), 
:deep(.v-field--center-affix .v-field__clearable), 
:deep(.v-field--center-affix .v-field__prepend-inner){
  	align-items: start;
}
:deep(.v-input__details){
	padding-inline: 16px;
}
</style>