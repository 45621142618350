import type { TreeNode } from '../types/treeNode';

let nextId = 1;

const titlePrimitive = (key: string, value: any) => {
	return `<span class='json-tree-node json-tree-leaf-key'>${key}</span> <span class='json-tree-leaf-value'>${value}</span>`;
}

const titleObject = (key: string) => {
	return `<span class='json-tree-node json-tree-node-object'>${key}</span>`;
}

const titleArrayRecord = (parentKey: string, index: number) => {
	//return `<span class='json-tree-node-array-index'>` + (parentKey ? `${parentKey}[${index}]` : `${index}`) + `</span>`;
	return `<span class='json-tree-node json-tree-node-array-index'>${index}</span>`;
}

function convertObjectToTree(obj: any, tag: any, parentKey: string = "", currentPath: string = "$", pathReturnsWholeArray?: boolean): TreeNode[] {
	const result: TreeNode[] = [];

	if (typeof obj !== 'object' || obj === null) {
		// Handle primitive values or null as a simple node, NO nesting
		if (parentKey) {
			result.push({
				id: nextId++,
				tag: tag,
				title: titlePrimitive(parentKey, obj),
				jsonpath: currentPath,
				key: parentKey,
				name: parentKey,
				value: obj
			});
		}
		return result;
	}
	if (Array.isArray(obj)) {
		// Handle arrays as child nodes with indexes as titles
		obj.forEach((item, index) => {
			let jsonPathIndex = (index + '') as string
			if (pathReturnsWholeArray) {
				jsonPathIndex = ':'
			}
			const childPath = `${currentPath}[${jsonPathIndex}]`;
			const children = convertObjectToTree(item, tag, `${index}`, childPath, pathReturnsWholeArray);

			if (children.length > 0) {
				result.push({
					id: nextId++,
					tag: tag,
					title: titleArrayRecord(parentKey, index),
					jsonpath: childPath,
					key: parentKey ? `${parentKey}[${index}]` : `${index}`,
					name: parentKey ? `${parentKey}[${index}]` : `Item[${index}]`,
					children: children,
				});
			} else {
				result.push(...children);
			}
		});
	} else {
		// Handle objects as nodes
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				const childPath = `${currentPath}.${key}`;

				if (typeof value !== 'object' || value === null) {
					result.push({
						id: nextId++,
						tag: tag,
						title: titlePrimitive(key, value),
						jsonpath: childPath,
						key: key,
						name: key,
						value: value
					});
				} else {
					const children = convertObjectToTree(value, tag, key, childPath, pathReturnsWholeArray);

					if (children.length > 0) {
						result.push({
							id: nextId++,
							tag: tag,
							title: titleObject(key),
							jsonpath: childPath,
							key: key,
							name: key,
							children: children,
						});
					} else {
						result.push(...children);
					}
				}
			}
		}
	}
	return result;
}

const objectToTree = (obj: any, tag?: any, includeRoot?: boolean, rootName?: string, pathReturnsWholeArray?: boolean): TreeNode[] => {
	nextId = 1;

	let tree: TreeNode[] = [];
	if (includeRoot) {
		tree = [{
			id: nextId++,
			tag: tag,
			title: titleObject(rootName || 'root'),
			jsonpath: '$',
			key: 'root',
			name: rootName || 'root',
			children: convertObjectToTree(obj, tag, '', '$', pathReturnsWholeArray),
		}];
		return tree
	} else {
		tree = convertObjectToTree(obj, tag, '', '$', pathReturnsWholeArray);

		if (tree.length > 0) {
			return tree;
		} else {
			return [{ id: nextId++, tag: tag, name:`${obj}`, title: `${obj}`, jsonpath: '$', value: obj }];
		}
	}
};

export { objectToTree };
export type { TreeNode }