///authorization/roles
import { defineStore } from 'pinia'
import { useApiStore } from './api';
import { PlatformVariableDto } from '../types/api';

export const usePlatformVariablesStore = defineStore('platformVariablesStore', () =>
{   
    const store = useApiStore();
    
    let detail = async (id: string) => (await store.api.platformVariables.platformVariablesDetail(id)).data;
    
    let list = async (o: { platformId: string }) => (await store.api.platformVariables.platformVariablesList(o)).data;

    let create = async (data: PlatformVariableDto) => (await store.api.platformVariables.platformVariablesCreate(data)).data;

    let update = async (id:string, data: PlatformVariableDto) => (await store.api.platformVariables.platformVariablesUpdate(id, data)).data;

    let del = async (id: string) => (await store.api.platformVariables.platformVariablesDelete(id)).data;

    return { detail, list, create, update, del }

});