import { ref, shallowRef, watch, computed } from 'vue'
import { VCombobox, VSelect, VSwitch, VTextarea, VTextField } from 'vuetify/lib/components/index.mjs';
import { ActionTriggerBaseDto, InputPropertyDto, JsonPropertyType, PublicActionDto, WorkflowDto } from '../../types/api';
import { VNumberInput } from 'vuetify/lib/labs/components.mjs';
import TipTapTextField from './TipTapTextField.vue';

const createFieldComponent = (fieldName: string, fieldSchema: InputPropertyDto, advancedTextEditor: boolean) => {
    const required = fieldSchema.required || false;
    const propertyName = fieldSchema.propertyName!;

    const baseConfig = {
        label: fieldName,
        hint: (required ? 'Required. ' : 'Optional. ') + (fieldSchema.description || ''),
        persistentHint: true,
        rules: required ? [(v: any) => !!v || 'Required. ' + (fieldSchema.description || '')] : [],
        clearable: !required
    }

    if (fieldSchema.enum && fieldSchema.enum.length > 0) {
        return {
            type: advancedTextEditor ? TipTapTextField : VSelect,
            props: {
                ...baseConfig,
                items: fieldSchema.enum,
                multiple: fieldSchema.propertyType == JsonPropertyType.Array,
            }
        }
    }

    switch (fieldSchema.propertyType) {
        case JsonPropertyType.Integer:
        case JsonPropertyType.Number:
            return {
                type: advancedTextEditor ? TipTapTextField : VNumberInput,
                props: {
                    ...baseConfig,
                    controlVariant: 'stacked',
                    min: fieldSchema.minimum ?? undefined,
                    max: fieldSchema.maximum ?? undefined,
                    default: fieldSchema.defaultValue ?? undefined,
                    step: fieldSchema.propertyType == JsonPropertyType.Integer ? 1 : 0.1,
                }
            }

        case JsonPropertyType.Boolean:
            return {
                type: advancedTextEditor ? TipTapTextField : VSwitch,
                props: {
                    ...baseConfig
                }
            }

        case JsonPropertyType.Array:
            if (fieldSchema?.enum && fieldSchema.enum.length > 0) {
                
                return {
                    type: advancedTextEditor ? TipTapTextField : VSelect,
                    props: {
                        ...baseConfig,
                        items: fieldSchema.enum,
                        multiple: true,
                        chips: true,
                    }
                }
            }
            return {
                type: advancedTextEditor ? TipTapTextField : VCombobox,
                props: {
                    ...baseConfig,
                    multiple: true,
                    chips: true,
                    deletableChips: true,
                }
            }

        case JsonPropertyType.Object:
            return {
                type: advancedTextEditor ? TipTapTextField : VTextarea,
                props: {
                    ...baseConfig,
                    autoGrow: true,
                    rows: 3,
                }
            }

        default:
            if (fieldSchema.minLength !== undefined && fieldSchema.minLength !== null) {
                baseConfig.rules.push((x: any) => {
                    if (x && x.length < fieldSchema.minLength!) {
                        return `${fieldSchema.displayName} must be equal to or greater than ${fieldSchema.minLength} characters`
                    }
                    return true
                })
            }
            if (fieldSchema.maxLength !== undefined && fieldSchema.maxLength !== null) {
                baseConfig.rules.push((x: any) => {
                    if (x && x.length > fieldSchema.maxLength!) {
                        return `${fieldSchema.displayName} must be less than or equal to ${fieldSchema.maxLength} characters`
                    }
                    return true
                })
            }
            if (fieldSchema.format == 'email') {
                baseConfig.rules.push((x: any) => {
                    if (x && !/^\S+@\S+\.\S+$/.test(x)) {
                        return `${fieldSchema.displayName} must be a valid email address.`
                    }
                    console.log('test')
                    return true
                })
                
            }
            return {
                type: advancedTextEditor ? TipTapTextField : VTextField,
                props: {
                    ...baseConfig,
                    pattern: fieldSchema.pattern,
                }
            }
    }
}

export { createFieldComponent }