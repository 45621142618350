/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import { aliases as lsAliases, ls } from '../iconsets/liveswitch';
import { aliases as mdiAliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import { VStepperVertical } from 'vuetify/labs/VStepperVertical'
import { VToolbarTitle } from 'vuetify/lib/components/index.mjs';

const aliases = { ...lsAliases, ...mdiAliases }

const liveswitchTheme = {
  dark: false,
  colors: {
    primary: '#6d3ef4',
    secondary: '#e4dbfd',
    tertiary: '#3E6AF4',
    unobtrusive: '#ccc',
    background: '#FFF',
    surface: '#FFF',
    error: '#C83EF4',
    info: '#9576f9',
    success: '#3E6AF4',
    warning: '#F10E45',
    'border-color': '#979EAF',
    status_released: '#00BA4A',
    status_underreview: '#FB8C00',
    status_submittedforreview: '#FB8C00',
    status_requireschanges: '#E17083',
    status_approved: '#6d3ef4',
    status_otherstatus: '#979EAF',
    environment_production: '#00BA4A',
    environment_development: '#0070FF',
  }
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
      components: {
        VStepperVertical,
      },
      icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
          ls
        }
      },
      aliases: {
        PrimaryBtn: VBtn,
        InlineBtn: VBtn,
        SecondaryBtn: VBtn,
        AddBtn: VBtn,
        CancelBtn: VBtn,
        SaveBtn: VBtn,
        BackBtn: VBtn,
        AltBtn: VBtn,
        NextBtn: VBtn,
        PrevBtn: VBtn,
      },
      defaults: {
        AltBtn: {
          color: 'tertiary',
          rounded: 'xl',
          elevation: 0
        },
        PrimaryBtn: {
          color: "primary",
          rounded: 'xl',
          elevation: 0
        },
        SecondaryBtn: {
          color: "secondary",
          rounded: 'xl',
          variant: "flat",
        },
        InlineBtn: {
          color: "white",
          rounded: 'xl',
          variant: "outlined",
        },
        SaveBtn: {
          color: 'primary',
          rounded: 'xl',
          elevation: 0
        },
        AddBtn: {
          color: 'primary',
          elevation: 0,
          rounded: 'xl',
          prependIcon: 'mdi-plus-circle-outline'
        },
        BackBtn: {
          color: 'unobtrusive',
          rounded: 'xl',
          elevation: 0
        },
        NextBtn: {
          color: 'primary',
          rounded: 'xl',
          elevation: 0,
          appendIcon: 'mdi-arrow-right',
          text: 'Next'
        },
        PrevBtn: {
          color: 'secondary',
          rounded: 'xl',
          elevation: 0,
          prependIcon: 'mdi-arrow-left',
          text: 'Previous'
        },
        VSelect: {
          variant: 'solo',
          elevation: 0,
          flat: true
        },
        VCombobox: {
          variant: 'solo',
          elevation: 0,
          flat: true
        },
        VSnackbar: {
          timeout: "2000",
          location: "top"
        },
        VSwitch: {
          color: 'primary'
        },
        VDataTableServer: {
          elevation: 0
        },
        VTextarea: {
          elevation: 0,
          variant: 'solo',
          flat: true
        },
        VNumberInput: {
          elevation: 0,
          variant: 'solo',
          flat: true
        },
        VTextField: {
          variant: 'solo',
          elevation: 0,
          flat: true,
          persistentHint: false
        },
        VField: {
          variant: 'solo',
          elevation: 0,
          flat: true,
          persistentHint: false
        },
        VBtn: {
          elevation: 0
        },
        VCard: {
          elevation: 3
        },
        VCardActions: {
          PrimaryBtn: {
            color: "primary",
            rounded: 'xl',
            
            variant:"flat"
          },
          SecondaryBtn: {
            color:"secondary",
            rounded: 'xl',
            variant:"flat"
          }
        },
        VStepper: {
          VCard: {
            elevation: 0
          }
        },
        VTab: {
          bgColor:"primary"
        },
        VToolbar: {
          color: "primary",
          rounded: true,
          flat: true,
          VToolbarTitle: {
            class:"white--text"
          }
        }
      },
      locale: {
        locale: 'en',
        fallback: 'en'
      },
      theme: {
        defaultTheme: 'liveswitchTheme',
        themes: {
          liveswitchTheme 
        }
    }
})
