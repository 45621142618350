<script lang="ts" setup>

const props = defineProps({
    helpKey: { type: String, required: true },
    helpSection:  { type: String, default: ''},
})

</script>
<template>
    <v-alert type="info">
        <slot></slot>
        <template v-slot:prepend>
            <v-help-icon size="large" color="white" :help-key="props.helpKey" :help-section="props.helpSection"></v-help-icon> 
        </template>
    </v-alert>
</template>