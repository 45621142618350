<script lang="ts" setup>
import { useHelpStore } from '@shared/store/help'

const props = defineProps({
    helpKey: { type: String, required: false },
    helpSection: { type: String, required: false },
})

const helpStore = useHelpStore();
const showHelp = (context: string) => {
    helpStore.setContext(context);
}
</script>
<template>
    <primary-btn v-bind="$attrs">
        <template #prepend v-if="props.helpKey">
            <v-icon @click.stop.prevent="showHelp(props.helpKey + '#' + props.helpSection)">mdi-help-circle-outline</v-icon>
        </template>
        <slot />
    </primary-btn>
</template>