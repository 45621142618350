<script lang="ts" setup>
import { WorkflowDto, Step } from '../../types/api';
import { ref, computed } from 'vue'
import JsonRender from '../extensions/JsonRender.vue';
import { TreeNode } from '../../types/treeNode';
import PlatformAvatar from '../platforms/PlatformAvatar.vue';
import { useWorkflowsStore } from '../../store/workflows';

const workflow = defineModel<WorkflowDto>('workflow', { required: true })
const step = defineModel<Step>('step', { required: true })

const emit = defineEmits(['picked'])

type Record = {
    id: string
    name: string,
    platformLogoUrl: string,
    sampleData: any
}

const workflowsStore = useWorkflowsStore()
const availableSteps = computed(() => {
    const steps = workflowsStore.getSharedParentsOfStep(workflow.value, step.value.id!)
    return steps
})

const selected = (data: TreeNode[]) => {
    emit('picked', data[0])
}

const expanded = ref(availableSteps.value.map((x, i) => i) as number[])

const titleOf = (step: Step, stepIndex: number) => {
    if (!step.id) {
        return '1. ' + workflow.value.triggerName
    }
    
    return step.name || stepIndex + '.'
}

</script>
<template>
    <div>
        <v-expansion-panels
            v-model="expanded"
            multiple
            class="ma-0 pa-0"
            >
            <v-expansion-panel
                :elevation="0"
                v-for="(step, stepIndex) in availableSteps"
                :key="stepIndex"
                :title="titleOf(step, stepIndex)"
            >
                <template #title class="pa-0">
                    <PlatformAvatar :is-tool="false" :logo-url="step.platformLogoUrl" />
                    <span class="ml-2">{{ titleOf(step, stepIndex) }}</span>
                </template>
                <template #text class="pa-0">
                    <JsonRender 
                    :include-root="true"
                    :tag="step"
                    :json="step.sampleData ?? '{}'" 
                    :select-strategy="undefined" 
                    @selected="selected"  />
                </template>
            </v-expansion-panel>
                
        </v-expansion-panels>
    </div>
</template>
<style scoped>
:deep(.v-expansion-panel-text__wrapper) {
    padding: 0;
}
:deep(.v-expansion-panel-title){
    padding: 16px 16px;
}
</style>