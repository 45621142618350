<script lang="ts" setup>
import { ref, watch } from 'vue';
import { TreeNode } from '../../types/treeNode'
import { objectToTree } from '../../utils/objectToTree'
import { VTreeview } from 'vuetify/labs/VTreeview'
import jp from 'jsonpath'

const props = withDefaults(defineProps<{
    tag?: any,
    json: string,
    select?: string | null | undefined,
    includeRoot?: boolean,
    pathReturnsWholeArray?: boolean,
    selectStrategy: 'classic' | 'single-leaf' | 'independent' | undefined,
    noDataMessage?: string,
    metaData?: Record<string, any>
}>(), {
    noDataMessage: 'No data to display',
    includeRoot: false,
    pathReturnsWholeArray: false,
});

const emit = defineEmits(['selected'])

const tree = ref([] as TreeNode[])

const parseJson = (json: string) => {
    const jObj = JSON.parse(json || '{}');
    
    if(jObj && Object.keys(jObj).length === 0){
        console.error('JsonRender: No JSON data provided')
    }else{
        tree.value = objectToTree(jObj, props.tag, props.includeRoot, 'All Data', props.pathReturnsWholeArray) as TreeNode[]
    }
}
parseJson(props.json)

watch(() => props.json, (newVal) => {
    parseJson(newVal)
})

const selection = ref([] as TreeNode[])
if (props.select) {
    const found = jp.query(tree.value, '$..[?(@.jsonpath=="' + props.select + '")]')

    if (found.length) {
        selection.value = [found[0].id]
    }
}

if(props.selectStrategy){
    watch(selection, (newVal) => {
        const result = []
        for (const val of newVal) {
            const v = jp.query(tree.value, '$..[?(@.id==' + val + ')]')
            if (v.length === 0) {
                
                console.error('Could not find node with id: ' + val)
                continue
            }
            if (props.selectStrategy == 'independent') {
                result.push(v[0])
            } else {
                if (!v[0].children) {
                    result.push(v[0])
                }
            }
        }
        emit('selected', result)
    }, { deep: true })
}


</script>
<template>
    <div>
        <v-treeview 
            v-if="tree.length > 0"
            v-model:selected="selection"
            :items="tree"
            :selectable="selectStrategy !== undefined"
            :select-strategy="selectStrategy || undefined"
            color="primary"
            open-all
            item-value="id"
            density="compact"
        >
            <template #title="{ item }" >
                <div v-html="item.title" @click="emit('selected', [item])"></div>
            </template>
            
        </v-treeview>
        <v-alert type="info" v-else>{{ noDataMessage }}</v-alert>
    </div>
</template>
<style scoped>
    :deep(.json-tree-node){
        padding:4px 4px;
        color:rgb(var(--v-theme-primary));
        background-color: rgba(var(--v-theme-primary-light));
        border-radius: 4px;
        display: inline-block;
        border:1px solid rgba(var(--v-theme-primary));
        line-height: 1;
    }
    :deep(.v-list-item-action){
        display:none;
    }
</style>