import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { StepInputFieldValue } from '../../../types/stepInput'


import WorkflowStepDataComponent from './WorkflowStepDataComponent.vue'

// https://tiptap.dev/docs/editor/core-concepts/schema

export default Node.create({
	name: 'WorkflowStepData',

	group: 'inline',

	atom: true,

	inline: true,

	selectable: true,

	addAttributes() {
		return {
		  value: {
			default: {}, // Add a default value
			parseHTML: element => {
			  // Parse the value from data-value attribute
			  try {
				return JSON.parse(element.getAttribute('data-value') || '{}')
			  } catch (e) {
				return {}
			  }
			},
			renderHTML: attributes => {
			  // Render the value to a data-value attribute
			  return {
				'data-value': JSON.stringify(attributes.value)
			  }
			}
		  }
		}
	  },
	
	  parseHTML() {
		return [{
		  tag: 'workflow-step-data',
		  getAttrs: element => {
			// Additional parsing if needed
			return {}
		  }
		}]
	  },
	
	  renderHTML({ HTMLAttributes }) {
		return ['workflow-step-data', mergeAttributes(HTMLAttributes)]
	  },
	
	  addNodeView() {
		return VueNodeViewRenderer(WorkflowStepDataComponent as any)
	  },
})