<script lang="ts" setup>
import { useHelpStore } from '@shared/store/help'
import { useAttrs, computed, ref } from 'vue'

const attrs = useAttrs()
const props = defineProps({
    helpKey: { type: String, required: false },
    helpSection: { type: String, required: false },
    showQuestion: { type: Boolean, default: false },
    showLearn: { type: Boolean, default: true },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key?: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})

const required = ref(false)
const rules = [] as Array<any>;
props.validators.forEach((validatorName: String) => {
    switch (validatorName) {
        case 'required':
            required.value = true
            rules.push((v: any) => !!v || `${attrs.label} is required.`)
            break;
        case 'url':
            rules.push((v: any) => !v ? true : /^(http|https):\/\/[^ "]+$/.test(v) || `${attrs.label} must be a valid URL.`)
            break;
    }
})

</script>
<template>
    <v-select 
        v-bind="$attrs"
        :rules="rules"
        :persistent-hint="true"
        :menu-icon="''"
        >
        <template #append-inner>
            <v-icon>mdi-menu-down
            </v-icon>
            <v-icon v-if="showQuestion" size="large" @click.stop.prevent="showHelp(props.helpKey)" :color="color">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <template v-slot:message="{ message }">
            <span v-html="message"></span> <span v-if="showLearn"><a style="cursor: pointer;" class="learn-more" 
                @click.stop.prevent="showHelp(props.helpKey + '#' + props.helpSection)">Learn more</a>.</span>
        </template>
        <template #label>{{ attrs.label }} <span v-if="required" class="required">(Required)</span></template> 
    </v-select>
</template>