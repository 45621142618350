///authorization/roles
import { defineStore } from 'pinia'
import {
    AuthLevel,
    AuthType,
    EnvironmentType,
    HttpMethodType,
    JsonPropertyType,
    PublishStatus,
    SchemaType,
    TriggerType,
    VariableType,
    WebhookType
} from '../types/api';

export const useEnumsStore = defineStore('enumsStore', () =>
{   
    const authTypesList = () => {
        const authTypes = []
        for (var prop in AuthType) {
            switch (prop) {
                case AuthType.ApiKey:
                    authTypes.push({ title: 'API Key', value: prop})
                    break;
                case AuthType.OAuth:
                    authTypes.push({ title: 'OAuth 2.0', value: prop})
                    break;
                default:
                    authTypes.push((AuthType as any)[prop])
                    break;
            }
            
        }
        return authTypes
    }

    const variableTypesList = () => {
        const variableTypes = [] as string[]
        for (var prop in VariableType) {
            variableTypes.push((VariableType as any)[prop])
        }
        return variableTypes
    };

    const oAuthGrantTypesList = () => {
        return [{
            value: "authorization_code",
            title: "Authorization Code"
        },{
            value: "client_credentials",
            title: "Client Credentials"
        }]
    }

    const oAuthRefreshGrantTypesList = () => {
        return [{
            value: "refresh_token",
            title: "Refresh Token"
        },{
            value: "client_credentials",
            title: "Client Credentials"
        }]
    }

    const httpContentTypesList = () => {
        return [{
            title: 'application/json', value: 'application/json'
        }, {
            title: 'text/xml', value: 'text/xml'
        },
        {
            title: 'application/x-www-form-urlencoded', value: 'application/x-www-form-urlencoded'
        }]
    }
    

    const stringFormats = [{ title: '- none -', key: '' }, 'date', 'date-time', 'password', 'byte', 'binary', 'email', 'hostname', 'ipv4', 'ipv6', 'uri', 'uuid']
    const stringFormatsList = () => {
        return stringFormats
    }

    const authLevelsList = () => {
        const authLevels = [] as string[]
        for (var prop in AuthLevel) {
            authLevels.push((AuthLevel as any)[prop])
        }
        return authLevels
    }

    const httpMethodsList = () => {
        const items = [] as string[]
        for (var prop in HttpMethodType) {
            items.push((HttpMethodType as any)[prop])
        }
        return items
    }

    const triggerTypesList = () => {
        const items = [] as string[]
        for (var prop in TriggerType) {
            if (prop === TriggerType.Polling) { // hide polling
                continue
            }
            items.push((TriggerType as any)[prop])
        }
        return items
    }

    const webhookTypesList = () => {
        const items = [] as string[]
        for (var prop in WebhookType) {
            items.push((WebhookType as any)[prop])
        }
        return items
    }

    const environmentTypesList = () => {
        const environments = [] as string[]
        for (var prop in EnvironmentType) {
            environments.push((EnvironmentType as any)[prop])
        }
        return environments
    }

    const jsonPropertyTypesList = () => {
        const typesList = [] as string[]
        for (var prop in JsonPropertyType) {
            typesList.push((JsonPropertyType as any)[prop])
        }
        return typesList
    }

    const schemaTypesList = () => {
        const typesList = [] as any[]
        for (var prop in SchemaType) {
            const v = (SchemaType as any)[prop]
            if (prop == 'Parameters') {
                // clarity for users
                typesList.push({ title: 'Query Parameters', value: v })
            } else {
                typesList.push(v)
            }
        }
        return typesList
    }

    const getHumanName = (name: string | undefined) => {
        if (!name) {
            return ''
        }
        return name.replace(/([A-Z])/g, ' $1').trim()
    }

    const getStatusesForProduction = (currentStatus: PublishStatus) => {
        // this is a state machine that manages the transitions between statuses
        switch (currentStatus) {
            case PublishStatus.UnderReview:
                return [{
                    title: 'Request Changes',
                    value: PublishStatus.RequiresChanges
                },{
                    title: 'Approve',
                    value: PublishStatus.Approved
                }]
            case PublishStatus.SubmittedForReview:
                return [{
                    title: 'Begin Review',
                    value: PublishStatus.UnderReview
                }]
            case PublishStatus.Approved:
                return [{
                    title: 'Archive',
                    value: PublishStatus.Archived
                }]
            case PublishStatus.Released:
                return [{
                    title: 'Rollback',
                    value: PublishStatus.Approved
                }]
            case PublishStatus.RequiresChanges:
                return [{
                    title: 'Submit for Review',
                    value: PublishStatus.SubmittedForReview
                }]
            case PublishStatus.Private:
                return [{
                    title: 'Submit for Review',
                    value: PublishStatus.SubmittedForReview
                }]
            case PublishStatus.Archived:
                return [{
                    title: 'Submit for Review',
                    value: PublishStatus.SubmittedForReview
                }]
            default:
                return []
        }
    }

    return {
        authTypesList,
        authLevelsList,
        environmentTypesList,
        getHumanName,
        getStatusesForProduction,
        httpMethodsList,
        jsonPropertyTypesList,
        schemaTypesList,
        stringFormatsList,
        triggerTypesList,
        webhookTypesList,
        httpContentTypesList,
        oAuthGrantTypesList,
        oAuthRefreshGrantTypesList,
        variableTypesList
    }
});