/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import HelpTextField from './components/form/HelpTextField.vue'
import HelpSelectField from './components/form/HelpSelectField.vue'
import HelpIcon from './components/form/HelpIcon.vue'
import HelpCheckbox from './components/form/HelpCheckbox.vue'
import HelpLink from './components/form/HelpLink.vue'
import HelpSwitch from './components/form/HelpSwitch.vue'
import ErrorAlert from './components/alerts/ErrorAlert.vue'
import SuccessAlert from './components/alerts/SuccessAlert.vue'
import Loader from './components/Loader.vue'
import HelpButton from './components/form/HelpButton.vue'
import HelpAlert from './components/form/HelpAlert.vue'

const app = createApp(App)
app.component('v-help-text-field', HelpTextField)
app.component('v-help-select', HelpSelectField)
app.component('v-help-icon', HelpIcon)
app.component('v-help-link', HelpLink)
app.component('v-help-checkbox', HelpCheckbox)
app.component('v-help-switch', HelpSwitch)
app.component('v-help-btn', HelpButton)
app.component('v-help-alert', HelpAlert)

app.component('error-alert', ErrorAlert)
app.component('success-alert', SuccessAlert)
app.component('loader', Loader)

app.use(VueMonacoEditorPlugin, {
    paths: {
      // You can change the CDN config to load other versions
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.50.0/min/vs'
    },
})

registerPlugins(app)

app.mount('#app')
